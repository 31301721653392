<ol>
  <li>
    <mat-form-field>
      <mat-label>Define node id:</mat-label>
      <input matInput [(ngModel)]="nodeId">
    </mat-form-field>
  </li>
  <li>
    <button mat-raised-button (click)="openDialog()">Get Details</button>
  </li>
  <li *ngIf="nodeId">
    You chose: <i>{{nodeId}}</i>
  </li>
</ol>