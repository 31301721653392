<!-- custom-date-time-picker.component.html -->
<mat-form-field appearance="fill">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="date" (dateChange)="onDateTimeChange()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  
  <mat-form-field appearance="fill">
    <mat-label>Choose a time</mat-label>
    <mat-select [formControl]="selectedTime" (selectionChange)="onDateTimeChange()">
      <mat-option *ngFor="let time of timeOptions" [value]="time">
        {{ time }}
      </mat-option>
    </mat-select>
  </mat-form-field>