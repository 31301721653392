<!-- Toolbar selection -->
<mat-toolbar class="colored">
  <mat-toolbar-row>
    <div class="toolbar">
      <a href="https://www.dna-evolutions.com" target="_blank">
        <img
          class="leftitem disappearable"
          src="./assets/misc/icon/dna-evolutions-logo-white-retina-and-angular.png"
          alt="map icon"
          matTooltip="Visit our company homepage https://www.dna-evolutions.com in a new tab."
      /></a>

      <div class="centeritem disappearablesmall">
        <strong><u>JOpt.</u></strong>
      </div>

      <div class="centeritem">
        <strong>TourOptimizer</strong>
      </div>

      <div class="centeritem">
        <strong> - Demo</strong> 
      </div>

      <div class="rightitem">
        <input
          type="image"
          width="40px;"
          matTooltip="Watch our Howto-Video and get further information."
          src="./assets/misc/icon/help-icon.png"
          (click)="openIntroductionDialog()"
        />
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Example selection -->
<p></p>
<mat-expansion-panel #mepexample="matExpansionPanel" class="wide" [expanded]="false">
  <mat-expansion-panel-header class="colored">
    <mat-panel-title>
      <div class="panelcaption">
        <div class="panelicon">
          <img
            style="padding-left: 10px; height: 20px"
            src="./assets/misc/icon/icon-example.png"
            alt="map icon"
          />
        </div>
        <div class="paneltitle">
          Select an example
          <strong>(Active: {{ getExampleTitle(activeExampleId) }})</strong>
        </div>
      </div>
    </mat-panel-title>

    <mat-panel-description></mat-panel-description>
  </mat-expansion-panel-header>

  <div class="boxinfo wideleft">
    <p class="boxinfotitle">Info</p>
    <p>
      You can choose from different predefined examples. The position of the
      nodes and resources are fixed in each example (except for <strong>Free moving Example</strong>). However, opening hours,
      working hours, etc. can be modified and tested. After selecting an example
      you can read its <strong>Example Description</strong>. To activate an
      example simpy click <strong>Load</strong>. After loading the example you
      can start
      <img
        style="padding-left: 2px; padding-right: 5px; height: 18px"
        src="./assets/misc/icon/icon-run.png"
        alt="run icon"
      />
      the optimization right away.
    </p>
  </div>

  <mat-divider></mat-divider>

  <div style="padding-top: 10px">
    <h3>Select an example:</h3>

    <mat-form-field appearance="fill">
      <mat-label>Select an element...</mat-label>
      <mat-select
        [(ngModel)]="curExampleId"
        (ngModelChange)="setExampelId($event)"
      >
        <mat-option
          *ngFor="let exampleDef of getExampledDefs()"
          [value]="exampleDef.exampleId"
        >
          {{ exampleDef.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="curExampleId" style="padding-bottom: 15px">
      <h3>Example Description:</h3>
      <div class="exampledesc">
        {{ getExampleDesc(curExampleId) }}
      </div>
    </div>

    <mat-divider></mat-divider>
    <div
      *ngIf="curExampleId && activeExampleId !== curExampleId"
      style="padding-top: 15px"
    >
      <button
        mat-raised-button
        matTooltip="Open example"
        (click)="loadExample(curExampleId); mepexample.expanded = false; mepopti.expanded = true; mepmap.expanded = true;"
        color="primary"
        cdkFocusInitial
      >
        Load: {{ getExampleTitle(curExampleId) }}
      </button>
    </div>

    <div *ngIf="!curExampleId" style="padding-top: 15px">
      <button
        mat-raised-button
        matTooltip="Select another example first"
        [disabled]="true"
        color="primary"
        cdkFocusInitial
      >
        Select another example first
      </button>
    </div>

    <div *ngIf="activeExampleId === curExampleId" style="padding-top: 15px">
      <button
        mat-raised-button
        matTooltip="Already loaded"
        [disabled]="true"
        color="primary"
        cdkFocusInitial
      >
        Already loaded
      </button>
    </div>
  </div>
</mat-expansion-panel>

<!-- Example selection END -->
<p></p>

<!-- START OPTIMIZATION -->
<mat-expansion-panel #mepopti="matExpansionPanel" class="wide" [expanded]="true">
  <mat-expansion-panel-header class="colored">
    <mat-panel-title>
      <div class="panelcaption">
        <div class="panelicon">
          <img
            style="height: 20px"
            src="./assets/misc/icon/icon-run.png"
            alt="run icon"
          />
        </div>
        <div class="paneltitle">Start the optimization and see the result</div>
      </div>
    </mat-panel-title>
    <mat-panel-description></mat-panel-description>
  </mat-expansion-panel-header>
  <div class="boxinfo wideleft">
    <p class="boxinfotitle">Info</p>
    <p>
      After pressing <strong>Start Optimization</strong> you can optionally set
      some weights parameters to tune the optimization for different goals
      <strong>OR</strong>
      simply use the predefined values and start it right away.
      <br />
      After a successful run, the result-dialog will open automatically. Later,
      you can also click on
      <strong>Show latest result</strong> to return to the result-dialog.
      Further, you can click on any item on the
      <strong>map</strong>
      <img
        style="padding-right: 5px; padding-left: 2px; height: 20px"
        src="./assets/misc/icon/icon-map.png"
        alt="map icon"
      />
      to see its latest result. Also, feel free to modify
      <img
        style="padding-right: 5px; padding-left: 2px; height: 20px"
        src="./assets/misc/icon/icon-modify.png"
        alt="map icon"
      />
      some Node
      <img
        style="padding-left: 2px; padding-right: 5px; height: 20px"
        src="./assets/maps/marker-icon-blue.png"
        alt="map icon"
      />
      and Resource
      <img
        style="padding-left: 2px; padding-right: 5px; height: 20px"
        src="./assets/maps/marker-icon-red.png"
        alt="res icon"
      />parameters.
    </p>
  </div>
  <mat-divider></mat-divider>
  <div style="margin-top: 10px">
    <app-prepare-run-optimization-view></app-prepare-run-optimization-view>
  </div>
</mat-expansion-panel>
<!-- START OPTIMIZATION END -->

<p></p>

<!-- MAP -->

<mat-expansion-panel #mepmap="matExpansionPanel" class="wide" [expanded]="true">
  <mat-expansion-panel-header class="colored">
    <mat-panel-title>
      <div class="panelcaption">
        <div class="panelicon">
          <img
            style="padding-left: 10px; height: 20px"
            src="./assets/misc/icon/icon-map.png"
            alt="map icon"
          />
        </div>
        <div class="paneltitle">See it on a Map</div>
      </div>
    </mat-panel-title>

    <mat-panel-description></mat-panel-description>
  </mat-expansion-panel-header>
  <div style="padding-top: 10px">
    <app-leaflet-map></app-leaflet-map>
  </div>
</mat-expansion-panel>

<!-- MAP END-->

<p></p>
<!-- ELEMENT SELECTOR -->
<mat-expansion-panel
  class="wide"
  [expanded]="false"
  (afterExpand)="focusElementSelectorPanel()"
>
  <mat-expansion-panel-header class="colored">
    <mat-panel-title>
      <div class="panelcaption">
        <div class="panelicon">
          <img
            style="padding-left: 10px; height: 20px"
            src="./assets/misc/icon/icon-modify.png"
            alt="modfiy icon"
          />
        </div>

        <div class="paneltitle">
          Nodes
          <img
            style="padding-left: 2px; height: 20px"
            src="./assets/maps/marker-icon-blue.png"
            alt="map icon"
          />
          and Resources
          <img
            style="padding-left: 2px; height: 20px"
            src="./assets/maps/marker-icon-red.png"
            alt="res icon"
          />
        </div>
      </div>
    </mat-panel-title>

    <mat-panel-description></mat-panel-description>
  </mat-expansion-panel-header>

  <div class="boxinfo wideleft" #elselectoranchor>
    <p class="boxinfotitle">Info</p>
    <p>
      Modifying an element is an <strong>optional step</strong>. You can start
      <img
        style="padding-left: 2px; padding-right: 5px; height: 18px"
        src="./assets/misc/icon/icon-run.png"
        alt="run icon"
      />
      the optimization right away. Further, on the map,
      <img
        style="padding-left: 2px; padding-right: 5px; height: 20px"
        src="./assets/misc/icon/icon-map.png"
        alt="map icon"
      />
      you can also click on the elements you want to interact with (modifying,
      starting a new run, checking the latest result).
    </p>
  </div>
  <mat-divider></mat-divider>

  <div style="margin-top: 10px">
    <h3>Please select a Node or a Resource:</h3>
    <app-optimization-elements-selector></app-optimization-elements-selector>
  </div>
</mat-expansion-panel>

<!-- ELEMENT SELECTOR END -->
<p></p>

<router-outlet> </router-outlet>

<p></p>
<div class="footer">
  <div>
    <a href="https://www.dna-evolutions.com" target="_blank"
      >&copy; {{currentYear}} DNA Evolutions GmbH</a
    >
    <a
      style="padding-left: 20px"
      href="https://www.dna-evolutions.com/legal-notice/"
      target="_blank"
      >Legal Notice</a
    >
  </div>
</div>
