<div id="mapContainer" class="map-container">
  <div id="map" [ngClass]="isFullScreen() ? 'fullscreenmap' : 'boundmap'">
    <div class="map-position-info disappearable">
      <span [innerHTML]="mcText"></span>
    </div>

    <div class="map-interact">
      <a [routerLink]="[]" [fragment]="'map'">
        <img
          *ngIf="isFullScreen()"
          style="height: 35px;"
          src="./assets/misc/icon/icon-reduce.png"
          (click)="toggleFullScreenMap()"
          alt="decrease icon"
          matTooltip="Reduce map size"
          class="dezoomicon iconopacity"
        />

        <img
          *ngIf="!isFullScreen()"
          style="height: 35px;"
          src="./assets/misc/icon/icon-enlarge.png"
          (click)="toggleFullScreenMap()"
          alt="decrease icon"
          matTooltip="Enlarge map size"
          class="zoomicon iconopacity"
        />
      </a>

      <img
        style="height: 40px;"
        src="./assets/misc/icon/icon-reset-view.png"
        (click)="refreshMapCenter()"
        alt="reset view icon"
        matTooltip="Reset zoom and position"
        class="zoomicon iconopacity"
      />
      <!--<p></p>-->

      <app-prepare-run-optimization-view
        asIcon="true"
      ></app-prepare-run-optimization-view>

      <img
      *ngIf="hasEventNodes()"
      style="height: 35px;"
      src="./assets/maps/marker-icon-green.png"
      (click)="openNonGeoEventsDialog()"
      alt="decrease icon"
      matTooltip="Open to show events (no geo location)"
      class="dezoomicon iconopacity"
    />
    </div>

    <div class="map-controll">
      <div style="margin-top: 10px"></div>
    </div>
  </div>
</div>
