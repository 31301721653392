<div *ngIf="curDetail; then existingDetail; else nonExistingDetail"></div>

<ng-template #existingDetail>
  <mat-list dense role="list">
    <mat-list-item role="listitem">
      <div class="mat-list-node-item-cap">Status:</div>
      <div class="mat-list-node-item-value dontbreak">
        {{ curDetail.scheduleStatus }}
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="mat-list-node-item-cap">Arrival:</div>
      <div class="mat-list-node-item-value dontbreak">
        {{ curDetail.arrivalTime | dateFormatPipe }}
      </div>
    </mat-list-item>
  </mat-list>

  <mat-list dense role="list">
    <mat-list-item role="listitem">
      <div class="mat-list-node-item-cap">Departure:</div>
      <div class="mat-list-node-item-value dontbreak">
        {{ curDetail.departureTime | dateFormatPipe }}
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="mat-list-node-item-cap">Duration:</div>
      <div class="mat-list-node-item-value dontbreak">
        {{ curDetail.durationTime | durationFormatPipe: "PT":"auto" }}
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="mat-list-node-item-cap">Idle:</div>
      <div class="mat-list-node-item-value dontbreak">
        {{ curDetail.idleTime | durationFormatPipe: "PT":"auto" }}
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="mat-list-node-item-cap">Transition:</div>
      <div class="mat-list-node-item-value dontbreak">
        {{ curDetail.transitionDistance | distanceFormatPipe: "m":"km" }}
      </div>
    </mat-list-item>

      <div *ngIf="isBigger(curDetail.earlyDeviation | durationFormatPipe: 'PT':'no_unit_s', 0)">
        <mat-list-item role="listitem">
          <div class="mat-list-node-item-cap violationtext">Early:</div>
          <div class="mat-list-node-item-value violation">
            {{ curDetail.earlyDeviation | durationFormatPipe: "PT":"auto" }}
          </div>
        </mat-list-item>
      </div>
      <div *ngIf="isBigger(curDetail.lateDeviation | durationFormatPipe: 'PT':'no_unit_s', 0)">
        <mat-list-item role="listitem">
          <div class="mat-list-node-item-cap violationtext">Late:</div>
          <div class="mat-list-node-item-value violation">
            {{ curDetail.lateDeviation | durationFormatPipe: "PT":"auto" }}
          </div>
        </mat-list-item>
      </div>
      <div *ngFor="let vio of curDetail.nodeViolations">
        <mat-list-item role="listitem">
          <div class="mat-list-node-item-cap violationtext">Violation:</div>
          <div class="mat-list-node-item-value violation">
            {{ vio.attribute }} - {{ vio.subAttribute }}
          </div>
        </mat-list-item>
      </div>
    </mat-list>

</ng-template>

<ng-template #nonExistingDetail>
  <p></p>
  Please start an optimization first or make sure the node did not get
  unassigned.
  <p></p>
</ng-template>