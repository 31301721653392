<h1 mat-dialog-title>Optimization Result (RAW)</h1>
<mat-divider></mat-divider>
<div mat-dialog-content style="max-height: 50vh">

  <div *ngIf="curResult; else nonExistingResult">
    <div class="scroll-box">
      <pre>{{ textSolution?.textSolution }}</pre>
    </div>
  </div>

  <ng-template #nonExistingResult>
    <p></p>
    Please start an optimization first or make sure the node did not get
    unassigned..
    <p></p>
  </ng-template>

</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="inline buttongroup">
  <button
    mat-raised-button
    matTooltip="Close this dialog."
    (click)="onCloseClick()"
    color="primary"
    cdkFocusInitial
  >
    Close
  </button>

  <a
    href="https://docs.dna-evolutions.com/java_examples/tutorials/tutorial_beginner/first_optimization/first_optimization.html#analyzing-the-result"
    target="_blank"
    matTooltip="Visit our documentation on Optimization Result."
    mat-raised-button class="mat-raised-button infobutton"
    >RAW Result</a
  >
</div>
