/**
 * DNA Evolutions - JOpt.TourOptimizer
 * This is DNA\'s JOpt.TourOptimizer service. A RESTful Spring Boot application using springdoc-openapi and OpenAPI 3. JOpt.TourOpptimizer is a service that delivers route optimization and automatic scheduling features to be easily integrated into any third-party application. JOpt.TourOpptimizer encapsulates all necessary optimization functionality and provides a comprehensive REST API that offers a domain-specific optimization interface for the transportation industry. The service is stateless and does not come with graphical user interfaces, map depiction or any databases. These extensions and adjustments are supposed to be introduced by the consumer of the service while integrating it into his/her own application. The service will allow for many suitable adjustments and user-specific settings to adjust the behaviour and optimization goals (e.g. minimizing distance, maximizing resource utilization, etc.) through a comprehensive set of functions. This will enable you to gain control of the complete optimization processes.This service is based on JOpt (null)
 *
 * The version of the OpenAPI document: 1.2.7-SNAPSHOT
 * Contact: info@dna-evolutions.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoadDimension } from './loadDimension';
import { Qualification } from './qualification';
import { Constraint } from './constraint';
import { NodeType } from './nodeType';
import { NodeColor } from './nodeColor';
import { INodeDepot } from './iNodeDepot';
import { OfferedNode } from './offeredNode';
import { OpeningHours } from './openingHours';


/**
 * The list of nodes
 */
export interface Node { 
    /**
     * The unique id of the node. It is not possible, to create mutliple elements (also Resources) with the same id.
     */
    id: string;
    /**
     * A custom extra info string that is attached to the Node.
     */
    extraInfo?: string;
    /**
     * The location id can relate a node to the connection of another node. For example  the node \'MyFirstNode\' and \'MySecondNode\' have the same location. It is sufficient to provide the  connection data for \'MyFirstNode\' and set the LocationId of \'MySecondNode\' to be \'MyFirstNode\'
     */
    locationId?: string;
    /**
     * The constraintAliasId. If set is used during constraint assessment instead of using the normal id.
     */
    constraintAliasId?: string;
    type: NodeType;
    /**
     * The list of non-overlapping openingHours of the nodes.
     */
    openingHours: Array<OpeningHours>;
    /**
     * The visitDuration defines how long a visitor needs to stay at the node.
     */
    visitDuration: string;
    /**
     * The constraints of this node
     */
    constraints?: Array<Constraint>;
    offeredNode?: OfferedNode;
    loadDimension?: LoadDimension;
    /**
     * The load
     */
    load?: Array<number>;
    /**
     * The qualifications of the node.
     */
    qualifications?: Array<Qualification>;
    /**
     * The lockdownTime
     */
    lockdownTime?: number;
    /**
     * The fixCost defines an abstract cost that arrises when this node is visited.
     */
    fixCost?: number;
    /**
     * The priority of the node. A higher priority leads to a higher cost if a node shows violations. As the Optimizer tries to reduce cost, a higher priority results in lower chance  of seeing violations on this node. However, if all nodes of an Optimization have a priority, the effect vanishes.
     */
    priority?: number;
    /**
     * The priorityFirst defines if we want a node to be the first node in a route-solution.
     */
    priorityFirst?: number;
    /**
     * The priorityLast defines if we want a node to be the last node in a route-solution.
     */
    priorityLast?: number;
    nodeColor?: NodeColor;
    /**
     * The minAutoFilterProtectedExecutions
     */
    minAutoFilterProtectedExecutions?: number;
    nodeDepot?: INodeDepot;
    /**
     * The routeDependentVisitDuration
     */
    routeDependentVisitDuration?: boolean | null;
    /**
     * The allowMoveToReduceFlexTime
     */
    allowMoveToReduceFlexTime?: boolean | null;
    /**
     * The minVisitDuration
     */
    minVisitDuration?: string;
    /**
     * The jointVisitDuration. If nodes are situated closely to each other (defined via property \'JOpt.JointVisitDuration.maxRadiusMeter\') a joint visit duration can be defined. For example, 3 nodes have a visit duration of 20 minutes each. The  joint visit duration for ALL nodes is set to be 10 minutes. Further, they are close enough to each other, that the joint visit duration logic can be triggered. The optimizer finds a solution in which all three nodes are visted in direct succession. The first node (of the three) needs to be visted for the original visit duration of 20 minutes. The seconds and third nodes only needs to be visited for 10 minutes.
     */
    jointVisitDuration?: string;
    /**
     * The returnStartDuration
     */
    returnStartDuration?: string;
    /**
     * The boolean isOptimizable. Defines if a node is optimizable. This property will be auto-defined by the optimizer..
     */
    isOptimizable?: boolean | null;
    /**
     * The boolean isOptional. If a node is optional, the Optimizer can decide on its own, if the node is visited or not. Usually, this settings only makes sense in PND problems.
     */
    isOptional?: boolean | null;
    /**
     * The boolean isUnassigned. Defines if a node was unassigned by the Optimizer.
     */
    isUnassigned?: boolean | null;
    /**
     * The boolean isOpeningHoursIncludesDuration. By default a node\'s openingHour defines the time-window  in which a task has to be fulfilled, meaning a Visitor has to arrive, work, and leave within that time-window. If isOpeningHoursIncludesDuration is set to false, the time-window only counts as arrival-window for the Resource.
     */
    isOpeningHoursIncludesDuration?: boolean | null;
    /**
     * The boolean isWaitOnEarlyArrival. In case a Resources reaches a node too early (before the start of the node\'s OpeningHours), the Resource can either start working direclty (false) or wait for the node to open (true, default).
     */
    isWaitOnEarlyArrival?: boolean | null;
    /**
     * The boolean isCausingIdleTimeCost. By default, waiting at a node to open is creating idle time cost. As the Optimizer tries to reduce cost, it will also try to reschedule nodes if idle time cost is generated. In some problem setups (especially problems of the kind: Low node count, high WorkingHours availability) it may be desired to keep the position of the nodes, even though idle time is created.
     */
    isCausingIdleTimeCost?: boolean | null;
    /**
     * The boolean isWaitOnEarlyArrivalFirstNode. In case a Resources reaches the FIRST node of a route too early (before the start of the node\'s OpeningHours),\"              + \" the Resource can either start working direclty (true) or wait for the FIRST node to open (false, default). This setting only takes action if isWaitOnEarlyArrival is set to true.
     */
    isWaitOnEarlyArrivalFirstNode?: boolean | null;
    /**
     * The boolean isStayNode defines if a node is capable to be a stay node. A stay node overrides the route termination element of a route, and the route start element of the next route and is  used in the context of \'overnight-stays\'.
     */
    isStayNode?: boolean | null;
    /**
     * The isWorkNode
     */
    isWorkNode?: boolean | null;
}

