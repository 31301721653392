{
  "name": "touroptimizer-angular-demo",
  "version": "2.0.6-alpine",
  "touroptimizerspecversion": "1.3.0-CLEANED-SNAPSHOT",
  "scripts": {
    "generate:api": "openapi-generator-cli generate -g typescript-angular -i ../openapi/touroptimizer_spec.json -o ./build/openapi",
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "postinstall": "ngcc",
    "get-version": "node -p \"require('./package').version\""
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.5",
    "@angular/cdk": "^19.1.3",
    "@angular/common": "^19.1.5",
    "@angular/compiler": "^19.1.5",
    "@angular/core": "^19.1.5",
    "@angular/forms": "^19.1.5",
    "@angular/material": "^19.1.3",
    "@angular/platform-browser": "^19.1.5",
    "@angular/platform-browser-dynamic": "^19.1.5",
    "@angular/router": "^19.1.5",
    "@swimlane/ngx-charts": "^20.5.0",
    "bootstrap": "^5.3.3",
    "bootstrap-icons": "^1.11.3",
    "esri-leaflet": "^3.0.12",
    "leaflet": "^1.9.4",
    "moment": "^2.30.1",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.6",
    "@angular/cli": "^19.1.6",
    "@angular/compiler-cli": "^19.1.5",
    "@openapitools/openapi-generator-cli": "^2.16.3",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.3",
    "@types/node": "^20.14.10",
    "angular-ide": "^0.9.77",
    "codelyzer": "^6.0.2",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "protractor": "~7.0.0",
    "ts-node": "~10.9.1",
    "tslint": "~6.1.0",
    "typescript": "^5.5.3"
  }
}
