/**
 * DNA Evolutions - JOpt.TourOptimizer
 * This is DNA\'s JOpt.TourOptimizer service. A RESTful Spring Boot application using springdoc-openapi and OpenAPI 3. JOpt.TourOpptimizer is a service that delivers route optimization and automatic scheduling features to be easily integrated into any third-party application. JOpt.TourOpptimizer encapsulates all necessary optimization functionality and provides a comprehensive REST API that offers a domain-specific optimization interface for the transportation industry. The service is stateless and does not come with graphical user interfaces, map depiction or any databases. These extensions and adjustments are supposed to be introduced by the consumer of the service while integrating it into his/her own application. The service will allow for many suitable adjustments and user-specific settings to adjust the behaviour and optimization goals (e.g. minimizing distance, maximizing resource utilization, etc.) through a comprehensive set of functions. This will enable you to gain control of the complete optimization processes.This service is based on JOpt (null)
 *
 * The version of the OpenAPI document: 1.2.7-SNAPSHOT
 * Contact: info@dna-evolutions.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The geographical address of the Position in case geo-coding will be applied.
 */
export interface GeoAddress { 
    /**
     * The locationId
     */
    locationId?: string;
    /**
     * The housenumber
     */
    housenumber?: string;
    /**
     * The streetname
     */
    streetname?: string;
    /**
     * The city
     */
    city?: string;
    /**
     * The county
     */
    county?: string;
    /**
     * The state
     */
    state?: string;
    /**
     * The statecode
     */
    statecode?: string;
    /**
     * The country
     */
    country?: string;
    /**
     * The macrocountry
     */
    macrocountry?: string;
    /**
     * The country code (ISO CODE)
     */
    countrycode?: string;
    /**
     * The postalcode
     */
    postalcode?: string;
    /**
     * The layer
     */
    layer?: string;
    /**
     * The source the data was extracted from
     */
    source?: string;
    /**
     * The accuracy
     */
    accuracy?: string;
    /**
     * This is a general score computed to calculate how likely result is what was asked for. It\'s meant to be a combination of all the information available to Pelias. It\'s not super sophisticated, and results may not be sorted in confidence-score order. In that case results returned first should be trusted more. Confidence scores are floating point numbers ranging from \'0.0\' to \'1.0\'.
     */
    confidence?: number;
    /**
     * The label
     */
    label?: string;
}

