<h1 mat-dialog-title>Properties of Node: {{ curNode?.id }}</h1>
<mat-divider></mat-divider>

<form name="form" (ngSubmit)="f.form.valid && onSaveClick()" #f="ngForm" [validTimeWindow]="['']" novalidate>
  <div mat-dialog-content style="max-height: 50vh">
    <mat-tab-group>
      <mat-tab label="Basic">
        <p></p>
        <ul>
          <li>
            <mat-label matTooltip="When is a node open for service? Click on cards to modify.">Modify OpeningHours
            </mat-label>
          </li>
        </ul>
        <p></p>

        <mat-accordion>
          <div *ngFor="let openinghours of openingHoursCopy; let i = index" [attr.data-index]="i">
            <mat-expansion-panel style="width: 95%; margin: 0 auto;">
              <mat-expansion-panel-header>
                <mat-panel-title> Opening hour </mat-panel-title>
                <mat-panel-description> # {{ i + 1 }} </mat-panel-description>
              </mat-expansion-panel-header>

              <p></p>
              <h5>
                <mat-label matTooltip="Till when the node should be visited.">OpeningHours start :
                </mat-label>
              </h5>


              <!-- XXX add back [ngxMatDatetimePicker]="pickerBegin" to input -->

              <!-- See https://github.com/h2qutc/angular-material-components/issues/370
                   AND https://github.com/h2qutc/angular-material-components/issues/348 -->
              <app-custom-date-time-picker [(ngModel)]="openinghours.begin" [endTime]="openinghours.end"
                name="twstart{{ i }}"></app-custom-date-time-picker>

              <!-- <mat-form-field style="width: 100% !important" appearance="fill">
                <mat-label>OpeningHours start</mat-label>
                

            
                
                <input
                  matInput
                  name="twstart{{ i }}"
                  placeholder="OpeningHours start"
                  [(ngModel)]="openinghours.begin"
                  [ngxMatDatetimePicker]="pickerBegin"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerBegin">
                </mat-datepicker-toggle>
               <ngx-mat-datetime-picker
                  #pickerBegin
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="15"
                  [stepSecond]="60"
                  [touchUi]="true"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>-->

              <p></p>
              <h5>
                <mat-label matTooltip="Till when the work at the node should be done.">
                  OpeningHours end:
                </mat-label>
              </h5>

              <app-custom-date-time-picker [(ngModel)]="openinghours.end" [startTime]="openinghours.begin"
                name="twend{{ i }}}"></app-custom-date-time-picker>

              <!-- XXX add back [ngxMatDatetimePicker]="pickerEnd" to input -->
              <!--<mat-form-field style="width: 100% !important" appearance="fill">
                <mat-label>OpeningHours end</mat-label>
                <input
                  matInput
                  name="twend{{ i }}"
                  placeholder="OpeningHours end"
                  [(ngModel)]="openinghours.end"
                  required
                />
                <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #pickerEnd
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="15"
                  [stepSecond]="60"
                  [touchUi]="true"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>-->

              <p></p>
            </mat-expansion-panel>
            <p></p>
          </div>
        </mat-accordion>
        <mat-divider></mat-divider>

        <!-- Duration -->

        <p></p>
        <ul>
          <li>
            <mat-label matTooltip="How long a Resource needs to stay at the node? Click on card to modify.">
              Modify Visit duration</mat-label>
          </li>
        </ul>
        <p></p>
        <mat-accordion>
          <mat-expansion-panel style="width: 95%; margin: 0 auto">
            <mat-expansion-panel-header>
              <mat-panel-title> Visit duration </mat-panel-title>
              <mat-panel-description>
                {{ visitDurationMinutes }} [min]
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card>
              <mat-form-field style="width: 100% !important">
                <h5>
                  <mat-label>Visit duration [min]</mat-label>
                </h5>
                <input matInput name="visitduration" #visitDuration min="1" max="999" pattern="\d*" placeholder="30"
                  maxLength="3" [(ngModel)]="visitDurationMinutes" type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) {this.value = this.value.slice(0, this.maxLength);} if (this.value<=0) {this.value = 1;}" />
                <mat-hint align="end">{{ visitDuration.value.length }} / 3</mat-hint>
              </mat-form-field>
            </mat-card>
          </mat-expansion-panel>
        </mat-accordion>
        <p></p>
        <mat-divider></mat-divider>
        <!-- Pillar -->
        <p></p>
        <ul>
          <li>
            <mat-label matTooltip="If checkbox is checked, the node act as captures node with hard-constrained opening hours.">
              As captured node (pillar):</mat-label>

              <mat-checkbox name="pillarCon" [(ngModel)]="isPillar" (ngModelChange)="togglePillar($event)">
              </mat-checkbox>
              {{ isPillar }}


            <!--<mat-checkbox name="pillarCon"
            [(ngModel)]="isGeoPillar(curNode)">
          
            </mat-checkbox>-->
            
            
          </li>
        </ul>
        <p></p>

        <!--<mat-checkbox
          name="pillarCon"
          [(ngModel)]="exclduingRes.isHard"
        >-->



        <p></p>
        <!-- hard constraint end -->
        <!-- Buttons etc-->

        <p></p>
        <mat-divider></mat-divider>
        <div class="wide boxteaser">
          <p class="boxteasertitle">Nodes in JOpt</p>
          <p class="dontbreakout">
            <span>
              Get to know more features, including 
              <strong>time windows, </strong><strong>pick-up and delivery, </strong><strong>zone-codes, </strong> 
              <strong>hard-constraints, </strong> and more...
            </span>
            <span style="margin-top: 0px; vertical-align: auto">
              Visit
              <a href="https://docs.dna-evolutions.com/overview_docs/special_features/Special_Features.html"
                target="_blank">Special features</a>
            </span>
            <span>
              <img style="
                  margin-top: 10px;
                  padding-left: 10px;
                  height: 40px;
                  vertical-align: middle;
                " src="./assets/misc/icon/click-icon.png" alt="click icon" /></span>
          </p>
        </div>
      </mat-tab>

      <mat-tab label="Advandced">
        <p></p>
        <mat-accordion>
          <mat-expansion-panel style="width: 95%; margin: 0 auto">
            <mat-expansion-panel-header>
              <mat-panel-title> Binding resources </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
              <div *ngFor="
                  let bingdingRes of binding;
                  let i = index
                " [attr.data-index]="i">
                <!-- hard constraint -->
                <mat-label>As hard constraint: </mat-label>

                <mat-checkbox name="bhard{{ i }}" [(ngModel)]="bingdingRes.isHard">
                  ({{ bingdingRes.isHard }})
                </mat-checkbox>
                <p></p>
                <!-- hard constraint end -->

                <div *ngFor="
                    let constraintResource of asBindingResourceType(bingdingRes).resources;
                    let j = index
                  " [attr.data-index]="j">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Binding resource </mat-panel-title>
                      <mat-panel-description>
                        {{ constraintResource.resourceId }} ({{
                        constraintResource.priority
                        }})
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-card>
                      <mat-form-field style="width: 100% !important">
                        <h5>
                          <mat-label>Resource Id</mat-label>
                        </h5>

                        <mat-form-field appearance="fill">
                          <mat-label>Ids</mat-label>
                          <mat-select name="bidselect{{ i }}{{ j }}" [(ngModel)]="constraintResource.resourceId">
                            <mat-option value="--">not present</mat-option>
                            <mat-option *ngFor="let id of resourceIds" [value]="id">{{ id }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </mat-form-field>

                      <mat-form-field style="width: 100% !important">
                        <h5>
                          <mat-label>Priority</mat-label>
                        </h5>
                        <input matInput name="bpriority{{ i }}{{ j }}" #priority min="1" max="10" pattern="\d*"
                          placeholder="30" maxLength="2" [(ngModel)]="constraintResource.priority" type="number"
                          oninput="javascript: if (this.value.length > this.maxLength) {this.value = this.value.slice(0, this.maxLength);} if (this.value<1) {this.value = 1;} if (this.value>10) {this.value = 10;}" />
                        <mat-hint align="end">{{ priority.value.length }} / 2</mat-hint>
                      </mat-form-field>
                    </mat-card>
                  </mat-expansion-panel>
                  <p></p>
                </div>
              </div>
            </mat-accordion>


          </mat-expansion-panel>
        </mat-accordion>
        <p></p>

        <mat-divider></mat-divider>
        <p></p>
        <!-- Excluding-->
        <mat-accordion>
          <mat-expansion-panel style="width: 95%; margin: 0 auto">
            <mat-expansion-panel-header>
              <mat-panel-title> Exclduing resources </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>


            <mat-accordion>
              <div *ngFor="
                  let exclduingRes of excluding;
                  let i = index
                " [attr.data-index]="i">
                <!-- hard constraint -->
                <mat-label>As hard constraint: </mat-label>

                <mat-checkbox name="exhard{{ i }}" [(ngModel)]="exclduingRes.isHard">
                  ({{ exclduingRes.isHard }})
                </mat-checkbox>
                <p></p>
                <!-- hard constraint end -->

                <div *ngFor="
                    let constraintResource of asExcludingResourceType(exclduingRes).resources;
                    let j = index
                  " [attr.data-index]="j">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Excluding resource </mat-panel-title>
                      <mat-panel-description>
                        {{ constraintResource.resourceId }} ({{
                        constraintResource.priority
                        }})
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-card>
                      <mat-form-field style="width: 100% !important">
                        <h5>
                          <mat-label>Resource Id</mat-label>
                        </h5>

                        <mat-form-field appearance="fill">
                          <mat-label>Ids</mat-label>
                          <mat-select name="exidselect{{ i }}{{ j }}" [(ngModel)]="constraintResource.resourceId">
                            <mat-option value="--">not present</mat-option>
                            <mat-option *ngFor="let id of resourceIds" [value]="id">{{ id }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </mat-form-field>

                      <mat-form-field style="width: 100% !important">
                        <h5>
                          <mat-label>Priority</mat-label>
                        </h5>
                        <input matInput name="expriority{{ i }}{{ j }}" #priority min="0" max="10" pattern="\d*"
                          placeholder="30" maxLength="2" [(ngModel)]="constraintResource.priority" type="number"
                          oninput="javascript: if (this.value.length > this.maxLength) {this.value = this.value.slice(0, this.maxLength);} if (this.value<0) {this.value = 0;} if (this.value>10) {this.value = 10;}" />
                        <mat-hint align="end">{{ priority.value.length }} / 2</mat-hint>
                      </mat-form-field>
                    </mat-card>
                  </mat-expansion-panel>
                  <p></p>
                </div>
              </div>
            </mat-accordion>


          </mat-expansion-panel>
        </mat-accordion>
        <p></p>

        <mat-divider></mat-divider>
        <div class="boxinfo wide">
          <p class="boxinfotitle">Info</p>
          <p class="dontbreakout">
            <strong>Binding Resources</strong> are the preferred visitors of a node (sorted by priority value).
            In case the constraint is defined as hard,
            the optimizer will have to schedule one of the binding Resources. 
            An <strong>excluding Resource</strong> is the counter-concept.
          </p>
        </div>
      </mat-tab>

      <div *ngIf="hasResult; then hasAResult; else hasNoResult"></div>

      <ng-template #hasAResult>
        <mat-tab>
          <ng-template matTabLabel>
            <span matBadge="1" matBadgePosition="before" matBadgeColor="accent" matBadgeOverlap="false">Last
              Result</span>
          </ng-template>
          <app-node-detail [nodeId]="curNode?.id"></app-node-detail>
        </mat-tab>
      </ng-template>

      <ng-template #hasNoResult>
        <mat-tab>
          <ng-template matTabLabel>
            <span matBadge="0" matBadgePosition="before" matBadgeColor="primary" matBadgeOverlap="false">Last
              Result</span>
          </ng-template>
          <app-node-detail [nodeId]="curNode?.id"></app-node-detail>
        </mat-tab>
      </ng-template>
    </mat-tab-group>
  </div>
  <p></p>
  <mat-divider></mat-divider>
  <div mat-dialog-actions class="inline buttongroup">

    <span *ngIf="!f.valid" [matTooltip]="f.valid ? enabledSaveTooltip : disabledSaveTooltip">
      <mat-icon>info</mat-icon>
    </span>
    <button mat-raised-button [matTooltip]="enabledSaveTooltip" color="primary" [disabled]="!f.valid" cdkFocusInitial>
      Save
    </button>

    <button mat-raised-button type="button" matTooltip="Close this dialog and discard all modifications." color="warn"
      (click)="onNoClick()">
      Cancel
    </button>

    <a mat-raised-button class="mat-raised-button infobutton"
      href="https://docs.dna-evolutions.com/java_examples/tutorials/tutorial_beginner/basic_elements/basic_elements.html#nodes"
      matTooltip="Visit our documentation on Nodes." target="_blank">Nodes</a>

  </div>
</form>