<h1 mat-dialog-title>Properties of Resource: {{ curRes?.id }}</h1>
<mat-divider></mat-divider>

<form
  name="form"
  (ngSubmit)="f.form.valid && onSaveClick()"
  #f="ngForm"
  [validTimeWindow]="['']"
  novalidate
>
  <div mat-dialog-content style="max-height: 50vh">
    <mat-tab-group>
      <mat-tab label="Basic">
        <p></p>
        <ul>
          <li>
            <mat-label
              matTooltip="When is a resource allowed to work? Click on cards to modify."
              >Modify WorkingHours
            </mat-label>
          </li>
        </ul>
        <p></p>

        <!-- OPENING HOURS -->
        <mat-accordion>
          <div
            *ngFor="let workinghours of workingHoursCopy; let i = index"
            [attr.data-index]="i"
          >
            <mat-expansion-panel
              style="width: 95%; margin: 0 auto"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Working hour </mat-panel-title>
                <mat-panel-description> # {{ i + 1 }} </mat-panel-description>
              </mat-expansion-panel-header>

              <p></p>
              <h5>
                <mat-label matTooltip="Till when the node should be visited."
                  >WorkingHours start :
                </mat-label>
              </h5>

              <app-custom-date-time-picker [(ngModel)]="workinghours.begin" name="twstart{{ i }}"></app-custom-date-time-picker>

              <!-- XXX add back [ngxMatDatetimePicker]="pickerBegin" to input -->

              <!-- See https://github.com/h2qutc/angular-material-components/issues/370
                   AND https://github.com/h2qutc/angular-material-components/issues/348 -->

               <!--<mat-form-field style="width: 100% !important" appearance="fill">
                <mat-label>WorkingHours start</mat-label>
                <input
                  matInput
                  name="twstart{{ i }}"

                  placeholder="WorkingHours start"
                  [(ngModel)]="workinghours.begin"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerBegin">
                </mat-datepicker-toggle>
               <ngx-mat-datetime-picker
                  #pickerBegin
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="15"
                  [stepSecond]="60"
                  [touchUi]="true"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>-->
              <p></p>
              <h5>
                <mat-label
                  matTooltip="Till when the work at the node should be done."
                >
                  WorkingHours end :
                </mat-label>
              </h5>

              <app-custom-date-time-picker [(ngModel)]="workinghours.end" name="twend{{ i }}"></app-custom-date-time-picker>

              <!-- XXX add back [ngxMatDatetimePicker]="pickerEnd" to input -->
              <!--<mat-form-field style="width: 100% !important" appearance="fill">
                <mat-label>WorkingHours end</mat-label>
                <input
                  matInput
                  name="twend{{ i }}"
                  
                  placeholder="WorkingHours end"
                  [(ngModel)]="workinghours.end"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #pickerEnd
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="15"
                  [stepSecond]="60"
                  [touchUi]="true"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>-->
              <p></p>
            </mat-expansion-panel>
            <p></p>
          </div>
        </mat-accordion>
        <!-- OPENING HOURS END -->
        <mat-divider></mat-divider>

        <!--MAX WORKINGTIME -->
        <p></p>
        <ul>
          <li>
            <mat-label
              matTooltip="How long a Resource needs to stay at the node? Click on card to modify."
            >
              Modify maximal working time</mat-label
            >
          </li>
        </ul>
        <p></p>
        <mat-accordion>
          <mat-expansion-panel
            style="width: 95%; margin: 0 auto"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Max Working time </mat-panel-title>
              <mat-panel-description>
                {{ maxTimeHours }} [hr]
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card>
              <mat-form-field style="width: 100% !important">
                <h5>
                  <mat-label>Max Working time [hr]</mat-label>
                </h5>
                <input
                  matInput
                  name="maxworkingtime"
                  #maxWorkingTime
                  min="1"
                  max="999"
                  pattern="\d*"
                  placeholder="30"
                  maxLength="3"
                  [(ngModel)]="maxTimeHours"
                  type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) {this.value = this.value.slice(0, this.maxLength);} if (this.value<=0) {this.value = 1;} if (this.value>24) {this.value = 24;}"
                />
              </mat-form-field>
            </mat-card>
          </mat-expansion-panel>
        </mat-accordion>
        <!--MAX WORKINGTIME END -->
        <p></p>
      </mat-tab>

      <mat-tab label="Advandced">
        <div class="wide boxteaser">
          <p class="boxteasertitle dontbreakout">Resources in JOpt</p>
          <p>
            <span>
              In JOpt Tour Optimizer, multiple features are integrated to define
              a Resource the way you need it. This includes, for example:
            </span>
            <li style="margin: 10px 0px 10px 0px">
              <strong>Skills:</strong> Assign skills to a Resource and make sure
              the right Node gets visited by the right Resource.
            </li>
            <li style="margin: 0 0 10px 0">
              <strong>Overnight Stay:</strong> Let the Optimizer find the best
              suitable location for an Overnight-Stay to save time and driving.
            </li>
            <li style="margin: 0 0 10px 0">
              <strong>Flexible start:</strong> Give the Resource the ability to
              start later or earlier, depending on what is most beneficial to
              solve an Optimization problem.
            </li>
            <li style="margin: 0 0 10px 0">
              <strong>Pick-up and Delivery:</strong> Use JOpt to solve your PND
              problem. Even Manufacturing-Planning is possible.   
            </li>
            <li style="margin: 0 0 10px 0">and more...</li>

            <mat-divider></mat-divider>
            <span style="margin-top: 0px; vertical-align: auto">
              Visit
              <a
                href="https://docs.dna-evolutions.com/overview_docs/special_features/Special_Features.html"
                target="_blank"
                >Special features</a
              >
            </span>
            <span>
              <img
                style="
                  margin-top: 10px;
                  padding-left: 10px;
                  height: 40px;
                  vertical-align: middle;
                "
                src="./assets/misc/icon/click-icon.png"
                alt="click icon"
            /></span>
          </p>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <p></p>
  <mat-divider></mat-divider>
  <div mat-dialog-actions class="inline buttongroup">
    <span *ngIf="!f.valid" [matTooltip]="f.valid ? enabledSaveTooltip : disabledSaveTooltip">
      <mat-icon>info</mat-icon>
    </span>
    <button
      mat-raised-button
      matTooltip="enabledSaveTooltip"
      color="primary"
      (click)="onSaveClick()"
      [disabled]="!f.valid"
      cdkFocusInitial
    >
      Save
    </button>

    <button
      mat-raised-button
      type="button"
      matTooltip="Close this dialog and discard all modifications."
      color="warn"
      (click)="onNoClick()"
    >
      Cancel
    </button>

    <a
      href="https://docs.dna-evolutions.com/java_examples/tutorials/tutorial_beginner/basic_elements/basic_elements.html#resources"
      target="_blank"
      matTooltip="Visit our documentation on Resources."
      mat-raised-button class="mat-raised-button infobutton"
      >Resources</a
    >
  </div>
</form>
