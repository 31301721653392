<div mat-dialog-content style="max-height: 50vh">
  <div *ngIf="curRoute; then existingRoute; else nonExistingRoute"></div>

  <ng-template #existingRoute>
    <div>
      <mat-expansion-panel
        [expanded]="true"
        class="mat-elevation-z1"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5>
              {{
                panelOpenState ? "Close Route Overview" : "Show Route Overview"
              }}
            </h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="background-color: whitesmoke">
          <mat-list dense role="list">
            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">RouteId:</div>
              <div class="mat-list-route-item-value">{{ curRoute.id }}</div>
            </mat-list-item>
            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">Resource:</div>

              <div class="mat-list-route-item-value">
                {{ curRoute.resourceId }}
              </div>
            </mat-list-item>
            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">Start:</div>
              <div class="mat-list-route-item-value">
                {{ curRoute.startTime | dateFormatPipe }}
              </div>
            </mat-list-item>
            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">Time:</div>
              <div class="mat-list-route-item-value">
                {{ curRoute.header.time | durationFormatPipe: "PT":"auto" }}
              </div>
            </mat-list-item>
            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">Idle:</div>
              <div class="mat-list-route-item-value">
                {{ curRoute.header.idleTime | durationFormatPipe: "PT":"auto" }}
              </div>
            </mat-list-item>

            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">Productive:</div>
              <div class="mat-list-route-item-value">
                {{ curRoute.header.prodTime | durationFormatPipe: "PT":"auto" }}
              </div>
            </mat-list-item>
            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">Transit:</div>
              <div class="mat-list-route-item-value">
                {{ curRoute.header.tranTime | durationFormatPipe: "PT":"auto" }}
              </div>
            </mat-list-item>
            <mat-list-item role="listitem">
              <div class="mat-list-route-item-cap">Distance:</div>
              <div class="mat-list-route-item-value">
                {{ curRoute.header.distance | distanceFormatPipe: "m":"km" }}
              </div>
            </mat-list-item>

            <div *ngFor="let vio of curRoute.header.routeViolations">
              <mat-list-item role="listitem">
                <div class="mat-list-route-item-cap violationtext">Violation:</div>
                <div class="mat-list-route-item-value violation">
                  {{ vio.attribute }} - {{ vio.subAttribute }}:
                  {{ vio.value | durationFormatPipe: "h":"auto" }}
                </div>
              </mat-list-item>
            </div>

          </mat-list>
          <p></p>
        </div>
      </mat-expansion-panel>
    </div>
    <p></p>
    <mat-divider></mat-divider>
    <p></p>
    <!-- -->

    <div class="frameborder">
      <h5 style="margin-left: 10px">Path</h5>
      <mat-vertical-stepper [linear]="false" [selectedIndex]="0" #stepper>
        <div
          *ngFor="let element of curRoute.elementDetails; let i = index"
          [attr.data-index]="i"
        >
          <mat-step
            completed="false"
            style="margin: 5px; background-color: whitesmoke"
          >
            <ng-template matStepLabel>
              {{ element.elementId }}
            </ng-template>

            <div style="background-color: whitesmoke">
              <app-node-detail [detail]="element"></app-node-detail>
            </div>
          </mat-step>
        </div>
      </mat-vertical-stepper>
    </div>

    <p></p>
  </ng-template>

  <ng-template #nonExistingRoute>
    <p></p>
    Please start an optimization first or make sure the node did not get
    unassigned..
    <p></p>
  </ng-template>
</div>

<mat-divider></mat-divider>
<div mat-dialog-actions class="inline">
  <button
    mat-raised-button
    matTooltip="Close this dialog."
    (click)="onCloseClick()"
    color="primary"
    cdkFocusInitial
  >
    Close
  </button>
  <a
    href="https://docs.dna-evolutions.com/java_examples/tutorials/tutorial_beginner/first_optimization/first_optimization.html#analyzing-a-route-result-header"
    target="_blank"
    matTooltip="Visit our documentation on Optimization Result."
    mat-raised-button class="mat-raised-button infobutton right"
    >Explain this result</a
  >
</div>
