<div class="consent-container">
    <div class="logo-toolbar">
        <img src="./assets/misc/icon/dna-evolutions-logo-white-retina-and-angular.png" alt="DNA Evolutions Logo" class="logo">
    </div>
    <h2>Data and Privacy Consent - DNA Evolutions GmbH</h2>
    <p>To enhance your experience, our angular demo website utilizes various online services:</p>
    <ul>
      <li><strong>OpenStreetMap</strong> for maps and location services.</li>
      <li><strong>Google Fonts</strong> for website typography.</li>
      <li>Links to <strong>external pages</strong> such as YouTube for video content.</li>
    </ul>
    <p>By accepting, you agree to the use of cookies and the download of data from these services. Your consent also applies to the links to external pages we provide.</p>
    <p>For more information, visit our <a href="https://dna-evolutions.com/" target="_blank" rel="noopener noreferrer">company homepage</a>.</p>
    <div class="actions">
      <button (click)="acceptConsent()">Accept</button>
      <button (click)="declineConsent()" class="decline">Decline</button>
    </div>
  </div>