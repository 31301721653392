<div *ngIf="!showAsIcon">
  <div *ngIf="myOptimizationOutput$ | async as result; else nonExistingResult">
    <div class="buttongroup">
      <button
        mat-raised-button
        color="primary"
        (click)="openDialog()"
        cdkFocusInitial
      >
        Start Optimization
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="openOptimizationResultDialog(result)"
        cdkFocusInitial
      >
        Show latest result
      </button>
    </div>
  </div>

  <ng-template #nonExistingResult>
    <div class="buttongroup">
      <button
        mat-raised-button
        color="primary"
        (click)="openDialog()"
        cdkFocusInitial
      >
        Start Optimization
      </button>
      <button
        mat-raised-button
        [disabled]="true"
        color="primary"
        cdkFocusInitial
      >
        Show latest result
      </button>
    </div>
  </ng-template>
</div>

<!-- ICON -->

<div *ngIf="showAsIcon">
  <div
    *ngIf="myOptimizationOutput$ | async as result; else nonExistingIconResult"
  >
  <div class="map-interact">
    <img
      style="height: 40px;padding-top: 15px;"
      src="./assets/misc/icon/icon-run2.png"
      (click)="openDialog()"
      alt="start icon"
      matTooltip="Start optimization"
      class="zoomicon iconopacity"
    />
    <p></p>
    <img
      style="height: 40px;padding-top: 5px;"
      src="./assets/misc/icon/icon-last-result.png"
      (click)="openOptimizationResultDialog(result)"
      alt="show latest result icon"
      matTooltip="Show latest result"
      class="zoomicon iconopacity"
    />
  </div>

  <ng-template #nonExistingIconResult>
    <img
      style="height: 40px;padding-top: 15px;"
      src="./assets/misc/icon/icon-run2.png"
      (click)="openDialog()"
      alt="start icon"
      matTooltip="Start optimization"
      class="zoomicon iconopacity"
    />
  </ng-template>
</div>
