/**
 * DNA Evolutions - JOpt.TourOptimizer
 * This is DNA\'s JOpt.TourOptimizer service. A RESTful Spring Boot application using springdoc-openapi and OpenAPI 3. JOpt.TourOpptimizer is a service that delivers route optimization and automatic scheduling features to be easily integrated into any third-party application. JOpt.TourOpptimizer encapsulates all necessary optimization functionality and provides a comprehensive REST API that offers a domain-specific optimization interface for the transportation industry. The service is stateless and does not come with graphical user interfaces, map depiction or any databases. These extensions and adjustments are supposed to be introduced by the consumer of the service while integrating it into his/her own application. The service will allow for many suitable adjustments and user-specific settings to adjust the behaviour and optimization goals (e.g. minimizing distance, maximizing resource utilization, etc.) through a comprehensive set of functions. This will enable you to gain control of the complete optimization processes.This service is based on JOpt (null)
 *
 * The version of the OpenAPI document: 1.2.7-SNAPSHOT
 * Contact: info@dna-evolutions.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Qualification } from './qualification';
import { Constraint } from './constraint';
import { StartReductionTimeDefinition } from './startReductionTimeDefinition';
import { StartReductionTimeIncludeDefinition } from './startReductionTimeIncludeDefinition';
import { StayOutCycleDefinition } from './stayOutCycleDefinition';
import { ReducedNodeEdgeConnectorItem } from './reducedNodeEdgeConnectorItem';
import { StartReductionTimePillarDefinition } from './startReductionTimePillarDefinition';
import { NodeColorCapacity } from './nodeColorCapacity';


/**
 * The list of non-overlapping workingHours.
 */
export interface WorkingHours { 
    /**
     * The begin of the Working hours.
     */
    begin: string;
    /**
     * The end of the Working hours.
     */
    end: string;
    /**
     * The zoneId of the Working hours.
     */
    zoneId: string;
    /**
     * The maximal time a Resource should work within the WorkingHour.
     */
    maxTime?: string;
    /**
     * The maximla distance a resource should cover within the WorkingHour.
     */
    maxDistance?: string;
    stayOutCycleDefinition?: StayOutCycleDefinition;
    startReductionTimeDefinition?: StartReductionTimeDefinition;
    startReductionTimePillarDefinition?: StartReductionTimePillarDefinition;
    startReductionTimeIncludeDefinition?: StartReductionTimeIncludeDefinition;
    /**
     * The local flexible time. In some cases a Resource should start working later compared to what is defined in the working hours. This way idle time can be reduced. The local flex time is the maximum a Resource is allowed to start working later, depending on the Optimization maybe flex time is not or only partially used.
     */
    localFlexTime?: string;
    /**
     * The localPostFlexTime
     */
    localPostFlexTime?: string;
    /**
     * The post flextime is only applied to reduce overtime.
     */
    localPostFlexTimeOnlyOnOvertime?: boolean | null;
    /**
     * The maxLocalPillarAfterHoursTime
     */
    maxLocalPillarAfterHoursTime?: string;
    /**
     * The nodeColorCapacities
     */
    nodeColorCapacities?: Array<NodeColorCapacity>;
    /**
     * The constraints for this working hour.
     */
    workingHoursConstraints?: Array<Constraint>;
    /**
     * The multiWorkingHoursConstraints
     */
    multiWorkingHoursConstraints?: Array<Constraint>;
    /**
     * The qualification of the Resource for this working hour. For example, the Resource is allowed to visit a node needing a skill (defined via a constraint) and the Resource is providing this skill.
     */
    qualifications?: Array<Qualification>;
    /**
     * The routeStartTimeHook
     */
    routeStartTimeHook?: string;
    /**
     * The list of hook connections
     */
    hookElementConnections?: Array<ReducedNodeEdgeConnectorItem>;
    /**
     * The boolean isAvailableForStay defines if this working hour is allowed to end at an overnight stay.
     */
    isAvailableForStay?: boolean | null;
    /**
     * The isClosedRoute boolean describes if a Resource has to visit the termination element of the Route. By default, the start element and the termination element of a Route is the Resource itself. In case of a closed route, by default, the Resource returns to its original starting location.
     */
    isClosedRoute?: boolean | null;
}

